<template>
  <div class='page-box'>
    <BaseSubTitle title="店铺信息"/>
    <div style="width:740px;">
      <ShopInfoForm :shopId="id" />
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api'
import ShopInfoForm from '@/components/ShopInfoForm'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'ShopInfo',
  components: {
    ShopInfoForm
  },
  setup (props, { root }) {
    const id = ref('')
    onMounted(() => {
      id.value = getSession(SHOPID)
    })
    return {
      id
    }
  }
}
</script>
